<template>
    <div class="experience-container">
        <h1 class="experience-h1">some of my recent product experience...</h1>
        <div class="projects-container">
            <div class="project-div">
                <img class="project-img scanzy" src="../assets/scanzy.png" alt="Scanzy">
                <p class="project-p description">
                    A barcode scanning software development kit
                </p>
                <a href="https://scanzy.com" target="_blank" class="project-link"><button class="project-button">learn more</button></a>
                <div class="scanzy-mockups">
                    <img class="project-img scanzy-mockup" src="../assets/phone-angle.png" alt="Scanzy">
                    <img class="project-img scanzy-mockup" src="../assets/phone-dark.png" alt="Scanzy">
                    <img class="project-img scanzy-mockup" src="../assets/phone-glare.png" alt="Scanzy">
                </div>
                <p class="project-p ul-description">
                    I led:
                </p>
                <ul class="project-ul">
                    <li>UX/UI design</li>
                    <li>Branding & graphic design</li>
                    <li>Website design & development</li>
                    <li>Mobile app design</li>
                    <li>Mobile app frontend development</li>
                </ul>
            </div>
            <div class="project-div">
                <img class="project-img il" src="../assets/il-logo-large.svg" alt="InventoryLab">
                <p class="project-p description il">
                    A suite of SaaS products that help eCommerce sellers run their businesses
                </p>
                <a href="https://inventorylab.com/solutions.html" target="_blank" class="project-link"><button class="project-button">learn more</button></a>
                <div class="scanzy-mockups">
                    <img class="project-img il-mockup" src="../assets/il-mockup.png" alt="Scanzy">
                </div>
                <p class="project-p ul-description">
                    I worked on:
                </p>
                <ul class="project-ul">
                    <li>UX/UI design for mobile and web apps</li>
                    <li>Frontend development</li>
                    <li>Website design & development</li>
                    <li>Email campaign design & development</li>
                </ul>
            </div>
        </div>
    </div>
    <div class="jobs-container">
        <h1 class="experience-h1">my design journey: bridging creativity and code</h1>

        <div class="job-container">
            <div class="job-img-container">
                <img class="job-img threecolts" src="../assets/threecolts.png" alt="Threecolts">
            </div>
            <div class="job-description-container">
                <h2 class="job-h2">Sep 2023 - Feb 2024 <a class="job-h2-name" href="https://www.threecolts.com" target="_blank">Threecolts</a></h2>
                <p class="job-p">Following the acquisition of InventoryLab by Threecolts, my role evolved further into that of a front-end engineer. Combining my design expertise with my development skills, I worked on projects that required a holistic approach, from creating functioning prototypes to collaborating with cross-functional teams on a daily basis. Being able to leverage both my UX/UI and front-end development skill sets allowed me to tackle challenges creatively and contribute effectively to the success of various projects.</p>
            </div>
        </div>
        <div class="job-container">
            <div class="job-img-container">
                <img  class="job-img il" src="../assets/il-logo.png" alt="InventoryLab">
            </div>
            <div class="job-description-container">
                <h2 class="job-h2">Jan 2021 - Sep 2023 <a class="job-h2-name" href="https://www.inventorylab.com" target="_blank">InventoryLab</a></h2>
                <p class="job-p">During my time at InventoryLab, I wore multiple hats as a hybrid UX/UI designer and front-end developer. Collaborating closely with my team, I played a key role in designing and implementing innovative feature sets for our web and mobile SaaS products. I also developed two marketing websites from the ground up. I grew personally and professionally during my time at InventoryLab, and I'll always be grateful to the amazing folks there.</p>
            </div>
        </div>
        <div class="job-container">
            <div class="job-img-container">
                <img  class="job-img wrfw" src="../assets/wrfw.png" alt="Women Run For Women">
            </div>
            <div class="job-description-container">
                <h2 class="job-h2">Jun 2020 - Jan 2021 <a class="job-h2-name" href="https://www.womenrunforwomen.com" target="_blank">Women Run For Women</a></h2>
                <p class="job-p">At Women Run For Women, I had the opportunity to dive headfirst into the world of professional design, focusing on web and UX/UI design for a non-profit dedicated to women's health. It was a very rewarding experience that not only allowed me to apply my skills, but also taught me invaluable lessons along the way. Working on meaningful projects in a supportive environment was incredibly rewarding and laid the foundation for my design career.</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ExperiencePage',
    data: function() {
    return {

        };
    },
    methods: {

    }
}
</script>