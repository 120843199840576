<template>
    <div class="story-container">
        <div class="story-pics-container">
            <img class="about-pic" src="../assets/about-pic-one.png" alt="">
            <img class="about-pic" src="../assets/about-pic-two.png" alt="">
            <img class="about-pic" src="../assets/about-pic-three.png" alt="">
        </div>
        <div class="story-words-container">
            <h1 class="story-h1">a little bit about me</h1>
            <p class="story-p">Interestingly enough my career journey started in finance. Fresh out of the University of Minnesota business school, I got my start in banking. It was a whirlwind introduction to professional life, and I learned many lessons I still apply to my career today.</p>
            <p class="story-p">After a while my inner creative rebelled. While I was grateful for the knowledge and relationships I had gained in the finance world, I craved more than spreadsheets and balance sheets. That's when I stumbled upon design. Through an apprenticeship, I dipped my toes into the design and front-end development waters, exploring what it takes to craft digital experiences for users. It was like unlocking a whole new world — one where I could blend my analytical skills with my artistic side and bring ideas to life in pixels and code.</p>
            <p class="story-p">Since then, I've been on a wild ride. Each job has been a crash course in learning. I've been able to engage my passion not just for aesthetics, but also the nuts and bolts of code. I've come to realize that marrying top-notch design with robust engineering isn't just desirable; it's essential. I'm here for every moment of that exciting journey.</p>
            <p class="story-p">When I'm not working, you can find me playing guitar, hiking with my Great Dane named Dave, or watching a classic TV series with my wife (Sopranos, anyone?).</p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'StoryPage',
    data: function() {
    return {

        };
    },
    methods: {

    }
}
</script>